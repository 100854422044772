import React from "react";
import { Button, ButtonToolbar, Drawer, Input, InputPicker, Modal, Row, Tag } from "rsuite";
import "../../styles/OrderTracking.css";
import { FormattedMessage } from "react-intl";
import { FileUploader } from "@containers/FileUploader/FileUploaderLink";

const UpdateOrderStatus = ({
  isPaid,
  visible,
  orderNumber,
  statusList,
  newOrderStatus,
  onClose,
  changeStatus,
  updateOrderStatus,
  changeComments,
  comments,
  onFileChange,
}) => {
  const [file, setFile] = React.useState(null);
  const fileInput = React.createRef();
  const fileHandler = (value) => {
    if (value.length) {
      setFile(value)
      onFileChange(value)
    }
  };

  const handleOpenFileBrowser = () => {
    console.log('submit')
  };

  return (
    <div>
      <Modal
        // className="modal-dialog-container"
        open={visible}
        size="xs"
        keyboard
        onClose={onClose}
      >
        <Modal.Header>
          <Drawer.Title className="font-medium">
            <div className='flex items-center'>
              <div className='mr-5'><Modal.Title>Savia #{orderNumber}</Modal.Title></div>
              {isPaid === false ?
                <div className='mr-5 flex items-center'><Tag color="red"> <FormattedMessage id="pending-payment" /></Tag></div>
                : null}
            </div>
          </Drawer.Title>
        </Modal.Header>
        <Modal.Body >
          <div className="pb-2 pt-0">
            <InputPicker
              placeholder="Estado de la orden"
              data={statusList}
              cleanable={false}
              onChange={changeStatus}
              value={newOrderStatus.id}
              block
            />
          </div>
          <div>
            <Input
              as="textarea"
              placeholder="Observaciones"
              onChange={changeComments}
              value={comments}
              rows="1"
            />
          </div>
          <div >
            <FileUploader
              fileHandler={fileHandler}
              handleOpenFileBrowser={handleOpenFileBrowser}
              uploadedFileName={file ? file[0]?.blobFile?.name : ''}
              fileInput={fileInput}
            />
          </div>
          <div>
            <ButtonToolbar className="pt-4">
              <Button appearance="primary" onClick={updateOrderStatus}>
                Actualizar
              </Button>
              <Button appearance="ghost" onClick={onClose}>
                Cancelar
              </Button>
            </ButtonToolbar>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default UpdateOrderStatus;
